import { render, staticRenderFns } from "./CaseAllowances.vue?vue&type=template&id=4a5a5240&scoped=true&"
import script from "./CaseAllowances.vue?vue&type=script&lang=js&"
export * from "./CaseAllowances.vue?vue&type=script&lang=js&"
import style0 from "./CaseAllowances.vue?vue&type=style&index=0&id=4a5a5240&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a5a5240",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCard,VCardTitle,VCol,VContainer,VDivider,VForm,VIcon,VRow,VSpacer,VTextField,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1488639183/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4a5a5240')) {
      api.createRecord('4a5a5240', component.options)
    } else {
      api.reload('4a5a5240', component.options)
    }
    module.hot.accept("./CaseAllowances.vue?vue&type=template&id=4a5a5240&scoped=true&", function () {
      api.rerender('4a5a5240', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/invoicing/CaseAllowances.vue"
export default component.exports
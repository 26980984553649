<template>
  <v-col>
    <v-card class="grey lighten-4">
      <v-card-title class="font-weight-light pb-0">
        Case Allowances
        <v-spacer />
        <!-- <v-menu
          bottom
          left
          offset-y
          :close-on-content-click="false"
          :value="menu"
        >
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon @click="menu = !menu">
              <v-icon>mdi-playlist-plus</v-icon>
            </v-btn>
          </template>
          <v-card class="grey lighten-4">
            <v-container class="pa-6 ma-0" style="height:250px;" fluid>
              <v-row>
                <v-col>
                  <v-textarea
                    background-color="white"
                    style="overflow-y:auto;"
                    no-resize
                    outlined
                    dense
                    rows="8"
                    label="Item Codes"
                    v-model="itemCodes"
                  />
                </v-col>
                <v-col>
                  <v-textarea
                    background-color="white"
                    style="overflow-y:auto;"
                    no-resize
                    outlined
                    dense
                    rows="8"
                    label="Allowances"
                    v-model="allowances"
                  />
                </v-col>
                <v-col>
                  <v-textarea
                    background-color="white"
                    style="overflow-y:auto;"
                    no-resize
                    outlined
                    dense
                    rows="8"
                    label="Quantities"
                    v-model="casesSold"
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-divider />
            <v-toolbar flat>
              <v-spacer />
              <v-btn
                class="green white--text text-capitalize"
                @click="formatValues"
              >
                Save
              </v-btn>
            </v-toolbar>
          </v-card>
        </v-menu> -->
      </v-card-title>
      <v-container class="white">
        <v-form ref="addForm">
          <div class="grey lighten-4 pa-2">
            <v-row dense style="height:60px;">
              <v-col>
                <v-text-field
                  background-color="white"
                  dense
                  label="Item Code"
                  outlined
                  ref="itemCode"
                  type="number"
                  v-model="caseAllowance.item_code"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  background-color="white"
                  dense
                  label="Quantity"
                  outlined
                  type="number"
                  v-model="caseAllowance.cases_sold"
                />
              </v-col>
              <v-col>
                <v-text-field
                  @keydown.enter="addCaseAllowance(caseAllowance)"
                  background-color="white"
                  dense
                  hint="Press Enter to Save"
                  label="Allowance"
                  outlined
                  prefix="$"
                  type="number"
                  v-model="caseAllowance.allowance"
                />
              </v-col>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    tabindex="-1"
                    class="ma-1"
                    @click="addCaseAllowance(caseAllowance)"
                  >
                    <v-icon color="green">mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add</span>
              </v-tooltip>
            </v-row>
          </div>
        </v-form>
        <v-divider />
        <v-form ref="list">
          <div
            class="grey lighten-4 px-2 pt-3"
            style="height: 255px; overflow-y: auto;"
          >
            <v-row
              v-for="(item, idx) in caseAllowances"
              :key="idx"
              align="center"
              dense
              style="height:50px;"
            >
              <v-col>
                <v-text-field
                  :rules="[(v) => !!v || 'Item Code Required']"
                  background-color="white"
                  dense
                  outlined
                  type="number"
                  v-model="item.item_code"
                  validate-on-blur
                />
              </v-col>
              <v-col>
                <v-text-field
                  :rules="[(v) => !!v || 'Cases Sold Required']"
                  background-color="white"
                  dense
                  outlined
                  type="number"
                  v-model="item.cases_sold"
                  validate-on-blur
                />
              </v-col>
              <v-col>
                <v-text-field
                  :rules="[(v) => !!v || 'Allowance Required']"
                  background-color="white"
                  dense
                  outlined
                  prefix="$"
                  type="number"
                  v-model="item.allowance"
                  validate-on-blur
                />
              </v-col>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    class="mb-6 mx-1"
                    icon
                    @click="deleteCaseAllowance(item)"
                  >
                    <v-icon color="red">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </v-row>
          </div>
        </v-form>
      </v-container>
    </v-card>
  </v-col>
</template>

<script>
import { notification } from '@/mixins/notification'

export default {
  name: 'caseAllowances',
  mixins: [notification],
  props: ['caseAllowances'],
  data() {
    return {
      itemCodes: '325088\n325089\n350815\n315128\n227876\n227066',
      allowances: '$0.40\n$0.40\n$0.40\n$0.40\n$0.40\n$0.40',
      casesSold: '1642\n1010\n40\n935\n169\n993',
      confirmDialog: false,
      caseAllowance: {},
      pageSize: 500,
      selectedItem: {},
      menu: false,
    }
  },
  methods: {
    addCaseAllowance(caseAllowance) {
      const valid =
        caseAllowance.item_code &&
        caseAllowance.cases_sold &&
        caseAllowance.allowance
      if (valid) {
        this.$emit('add', caseAllowance)
        this.caseAllowance = {}
        this.$refs.addForm.resetValidation()
        this.notify('success', 'Case Allowance Successfully Added')
      } else {
        this.notify('error', 'Missing Required Field(s)')
      }
      this.$refs.itemCode.focus()
    },
    // formatValues() {
    //   const itemCodeKeys = this.itemCodes.split('\n').map((ick, idx) => {
    //     return { item_code: ick, index: idx }
    //   })
    //   const allowanceKeys = this.allowances
    //     .replace(/\$|,/g, '')
    //     .split('\n')
    //     .map((ak, idx) => {
    //       return { allowance: ak, index: idx }
    //     })
    //   const casesSoldKeys = this.casesSold.split('\n').map((csk, idx) => {
    //     return { cases_sold: csk, index: idx }
    //   })

    //   const allValues = itemCodeKeys.map((ick) => {
    //     let object = {}
    //     object.item_code = ick.item_code
    //     allowanceKeys.forEach((akv) => {
    //       object.allowance = akv.allowance
    //       casesSoldKeys.forEach((cskv) => {
    //         object.cases_sold = cskv.cases_sold
    //       })
    //     })
    //     this.addCaseAllowance(object)
    //   })
    //   this.menu = false
    // },
    deleteCaseAllowance(caseAllowance) {
      this.$emit('delete', caseAllowance)
    },
    checkValue(value) {
      if (value === undefined) {
        return 'N/A'
      } else {
        return value
      }
    },
    removeItem(item) {
      const groupsIndex = this.items.indexOf(item, 0)
      this.items.splice(groupsIndex, 1)
      this.notify('success', 'Item Successfully Deleted')
      this.$forceUpdate()
    },
  },
}
</script>
<style scoped>
@import '../../assets/styles/global.css';
</style>

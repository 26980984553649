var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    [
      _c(
        "v-card",
        { staticClass: "grey lighten-4" },
        [
          _c(
            "v-card-title",
            { staticClass: "font-weight-light pb-0" },
            [_vm._v(" Case Allowances "), _c("v-spacer")],
            1
          ),
          _c(
            "v-container",
            { staticClass: "white" },
            [
              _c("v-form", { ref: "addForm" }, [
                _c(
                  "div",
                  { staticClass: "grey lighten-4 pa-2" },
                  [
                    _c(
                      "v-row",
                      { staticStyle: { height: "60px" }, attrs: { dense: "" } },
                      [
                        _c(
                          "v-col",
                          [
                            _c("v-text-field", {
                              ref: "itemCode",
                              attrs: {
                                "background-color": "white",
                                dense: "",
                                label: "Item Code",
                                outlined: "",
                                type: "number"
                              },
                              model: {
                                value: _vm.caseAllowance.item_code,
                                callback: function($$v) {
                                  _vm.$set(_vm.caseAllowance, "item_code", $$v)
                                },
                                expression: "caseAllowance.item_code"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          [
                            _c("v-text-field", {
                              attrs: {
                                "background-color": "white",
                                dense: "",
                                label: "Quantity",
                                outlined: "",
                                type: "number"
                              },
                              model: {
                                value: _vm.caseAllowance.cases_sold,
                                callback: function($$v) {
                                  _vm.$set(_vm.caseAllowance, "cases_sold", $$v)
                                },
                                expression: "caseAllowance.cases_sold"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          [
                            _c("v-text-field", {
                              attrs: {
                                "background-color": "white",
                                dense: "",
                                hint: "Press Enter to Save",
                                label: "Allowance",
                                outlined: "",
                                prefix: "$",
                                type: "number"
                              },
                              on: {
                                keydown: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.addCaseAllowance(_vm.caseAllowance)
                                }
                              },
                              model: {
                                value: _vm.caseAllowance.allowance,
                                callback: function($$v) {
                                  _vm.$set(_vm.caseAllowance, "allowance", $$v)
                                },
                                expression: "caseAllowance.allowance"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "ma-1",
                                          attrs: { icon: "", tabindex: "-1" },
                                          on: {
                                            click: function($event) {
                                              return _vm.addCaseAllowance(
                                                _vm.caseAllowance
                                              )
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "green" } },
                                          [_vm._v("mdi-plus")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          },
                          [_c("span", [_vm._v("Add")])]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("v-divider"),
              _c("v-form", { ref: "list" }, [
                _c(
                  "div",
                  {
                    staticClass: "grey lighten-4 px-2 pt-3",
                    staticStyle: { height: "255px", "overflow-y": "auto" }
                  },
                  _vm._l(_vm.caseAllowances, function(item, idx) {
                    return _c(
                      "v-row",
                      {
                        key: idx,
                        staticStyle: { height: "50px" },
                        attrs: { align: "center", dense: "" }
                      },
                      [
                        _c(
                          "v-col",
                          [
                            _c("v-text-field", {
                              attrs: {
                                rules: [
                                  function(v) {
                                    return !!v || "Item Code Required"
                                  }
                                ],
                                "background-color": "white",
                                dense: "",
                                outlined: "",
                                type: "number",
                                "validate-on-blur": ""
                              },
                              model: {
                                value: item.item_code,
                                callback: function($$v) {
                                  _vm.$set(item, "item_code", $$v)
                                },
                                expression: "item.item_code"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          [
                            _c("v-text-field", {
                              attrs: {
                                rules: [
                                  function(v) {
                                    return !!v || "Cases Sold Required"
                                  }
                                ],
                                "background-color": "white",
                                dense: "",
                                outlined: "",
                                type: "number",
                                "validate-on-blur": ""
                              },
                              model: {
                                value: item.cases_sold,
                                callback: function($$v) {
                                  _vm.$set(item, "cases_sold", $$v)
                                },
                                expression: "item.cases_sold"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          [
                            _c("v-text-field", {
                              attrs: {
                                rules: [
                                  function(v) {
                                    return !!v || "Allowance Required"
                                  }
                                ],
                                "background-color": "white",
                                dense: "",
                                outlined: "",
                                prefix: "$",
                                type: "number",
                                "validate-on-blur": ""
                              },
                              model: {
                                value: item.allowance,
                                callback: function($$v) {
                                  _vm.$set(item, "allowance", $$v)
                                },
                                expression: "item.allowance"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass: "mb-6 mx-1",
                                            attrs: { icon: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteCaseAllowance(
                                                  item
                                                )
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "red" } },
                                            [_vm._v("mdi-trash-can-outline")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [_c("span", [_vm._v("Delete")])]
                        )
                      ],
                      1
                    )
                  }),
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }